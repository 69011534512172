<template>
    <div class="download-wrap" :style="{ background: 'url(' + bgUrl + ')', 'min-height': clientHeight }"> 
        <!-- 一体机主图 -->
        <img class="banner" :src="appData.img_device && appData.img_device.content" alt="" />
        <img class="title_img" :src="appData.img_title && appData.img_title.content" alt="" />
        <img class="des_img" :src="appData.img_sub_title && appData.img_sub_title.content" alt="" />

        <img class="top_img" :src="appData.img_order && appData.img_order.content" alt="" />
        <img class="bottom_img" :src="appData.img_restaurant && appData.img_restaurant.content" alt="" />
        <div class="download-btn">
            <a class="btn" download="I餐飲HD一體機點餐系統" @click="download">{{ isZH ? appData.download&&appData.download.content || '立即下載' : appData.download&&appData.download.content||'download' }}</a>
        </div>
        <action-sheet v-model="show" :actions="actions" @select="onSelect" :round="false" :close-on-click-action="true" />
    </div>
</template>

<script>
import ActionSheet from 'vant/lib/action-sheet';
import 'vant/lib/action-sheet/style';
import Toast from 'vant/lib/toast';
import 'vant/lib/toast/style';
import { mapGetters } from 'vuex';
export default {
    name: 'Download',
    components: {
        ActionSheet,
    },
    computed: {
        ...mapGetters({
            appData: 'app/icateringHdData',
        }),
        bgUrl() {
            return (this.appData.bg_img && this.appData.bg_img.content) || '';
        },
        isZH() {
            return this.$route.query.lang ? this.$route.query.lang == 'zh_HK' : navigator.language.toLowerCase().includes('zh');
        },
    },
    data() {
        return {
            clientHeight: '100vh',
            show: false,
            type: '',
            info: {},
            aliClient: null,
            actions: [
                { name: this.isZH ? '本地下載' : 'Local Download', type: 0 },
                {
                    name: this.isZH ? 'Google Play下載' : 'Google Play Download',
                    type: 1,
                },
            ],
            envId: 2, // env环境，默认生态产適用環境：0-測試；1-灰度；2-生產
        };
    },
    async created() {
        let clientHeight = this.getViewPortHeight();
        this.clientHeight = clientHeight > 0 ? `${clientHeight}px` : '100vh';

        // 获取环境
        this.envId = await this.getEnvId();

        await this.getMobile();
        this.actions = [
            { name: this.isZH ? '本地下載' : 'Local Download', type: 0 },
            {
                name: this.isZH ? 'Google Play下載' : 'Google Play Download',
                type: 1,
            },
        ];
    },
    async mounted() {},
    methods: {
        // 获取环境  envIdentification:0-测试；1-灰度；2-生产
        getEnvId() {
            return new Promise((resolve) => {
                let env = process.env.NODE_ENV || 'tests',
                    envId = 0;
                switch (env) {
                    case 'canary':
                        envId = 1;
                        break;
                    case 'tests':
                        envId = 0;
                        break;
                    case 'production':
                        envId = 2;
                        break;
                }
                resolve(envId);
            });
        },

        // 获取浏览器窗口的可视区域的高度
        getViewPortHeight() {
            return document.documentElement.clientHeight || document.body.clientHeight || 0;
        },
        onSelect(item) {
            if (item.type === 0) {
                let src = this.info.path;
                if (!src) return Toast(this.$t('m.no_source'));
                let iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = src;
                document.getElementsByTagName('body')[0].appendChild(iframe);
            } else {
                let _href = (this.appData.downloadLink_google && this.appData.downloadLink_google.content) || null;
                if (!_href) return Toast(this.$t('m.no_source'));
                let alink = document.createElement('a');
                alink.href = _href;
                alink.click();
            }
            this.downloadCount();
        },
        async download() {
            if (this.type === 0) {
                //安卓
                this.show = true;
            } else {
                let _href = (this.appData.downloadLink && this.appData.downloadLink.content) || null;
                if (!_href) return Toast(this.$t('m.no_source'));
                var a = document.createElement('a');
                a.href = _href;
                a.click();
            }
        },
        async downloadCount() {
            // 用户点击了下载按钮 应该调统计下载次数接口了
            const id = this.info.id;
            const { success } = await this.$axios({
                url: `/admin/app/downloadStatistics?id=${id}`,
            });
            if (success) {
                console.log('下载统计成功');
            }
        },
        async getInfo() {
            const { success, result } = await this.$axios({
                url: `/admin/app/android/version/20/${this.envId}`,
            });
            if (success) {
                this.info = result;
            } else {
                // console.log(data.message);
            }
        },
        // 获取用户代理系统类型
        async getMobile() {
            const ua = navigator.userAgent.toLowerCase();
            console.log(ua);
            if (ua.indexOf('iphone') != -1) {
                this.type = 1;
                return 'ios';
            } else if (ua.indexOf('android') != -1) {
                this.type = 0;
                await this.getInfo();
                return 'android';
            } else if (ua.indexOf('windows') != -1) {
                return 'windows';
            } else {
                return 'none';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.download-wrap {
    position: relative;
    background-image: url('~@/assets/imgs/HD/image_element@2x.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right bottom;
    .banner {
        width: 45vw;
        position: fixed;
        bottom: 0;
        right: 0;
    }
    .title_img {
        width: 35vw;
        min-width: 420px;
        position: fixed;
        top: 15vh;
        left: 5vw;
    }
    .des_img {
        width: 35vw;
        min-width: 460px;
        position: fixed;
        top: 30vh;
        left: 5vw;
    }
    .top_img {
        width: 5.4vw;
        position: fixed;
        bottom: 31vw;
        right: 7vw;
    }
    .bottom_img {
        width: 5.4vw;
        position: fixed;
        right: 45vw;
        bottom: 5vw;
    }
}
.download-body {
    width: 100vw;
    padding-top: 6vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99;
    &-top {
        display: flex;
        margin-bottom: 24px;
        img {
            margin-right: 12px;
        }
        .title_box {
            text-align: left;
            color: #6b5531;
            margin-bottom: 16px;
            height: 70px;
            .title {
                font-size: 28px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
            }
            .sub_title {
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
            }
        }
    }

    .img_box {
        position: relative;
        margin-bottom: 45px;
        z-index: 1;
        .img_one {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 25vw;
        }
        .img_two {
            position: absolute;
            bottom: -40px;
            right: 0;
            width: 50vw;
            z-index: -1;
        }
    }
}
.download-btn {
    z-index: 100;
    position: fixed;
    width: 15vw;
    min-width: 200px;
    left: 5vw;
    top: 50%;
    height: 64px;
    background: #6b5531;
    border-radius: 10px;
    text-align: center;
    a {
        width: 100%;
        height: 100%;
        display: block;
        font-size: 1.5vw;
        min-width: 80px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 64px;
        letter-spacing: 15px;
    }
}
</style>
